import { StoreModules } from "@/store";
import { Module } from "vuex";
import { TaskCategory } from "@/models/task";
import { Account } from "@/interfaces/Account";

export interface AccountFilters {
  filtersByPage: Map<string, Account | null>;
}

const module: Module<AccountFilters, StoreModules> = {
  namespaced: true,
};

module.state = {
  filtersByPage: new Map(),
};

module.getters = {
  getFilter: (state) => (category: TaskCategory) => {
    return state.filtersByPage.get(category);
  },
};

module.mutations = {
  setFilter(state, payload: [string, Account | null]) {
    const [category, account] = payload;
    state.filtersByPage.set(category, account);
  },
};

export default module;
